import { JobHealthBaseFragment } from '@upper/graphql/internal'
import { classNames, Text } from '@upper/ui'
import { fullPercentageForValue } from '@upper/utils'
import { forwardRef } from 'react'
import { jobHealthColorForValue } from '../../utils/job'

type JobHealthInfoProps = Omit<JobHealthBaseFragment, '__typename'>
export const JobHealthInfo = forwardRef<any, JobHealthInfoProps>(
  (
    {
      healthPercentage,
      daysToStaffingDeadline,
      sourcingCount,
      matchingCount,
      proposedCount,
      appliedCount,
      deadlineInDays,
    },
    ref
  ) => {
    const fullPercentage = fullPercentageForValue(healthPercentage)
    const colors = jobHealthColorForValue(healthPercentage)

    return (
      <div ref={ref} className="px-3 py-2 text-gray-dark min-w-[200px]">
        <header className="flex items-center justify-between border-b border-gray-light py-1">
          <Text variant="subtitle" className="text-gray-darkest">
            Job Health
          </Text>
          <div
            ref={ref}
            className={classNames(
              'rounded-md inline-flex w-7 h-7 items-center justify-center font-mono'
            )}
            style={{ color: colors.background }}
          >{`${fullPercentage}%`}</div>
        </header>
        <div className="space-y-1 text-sm py-1">
          <InfoRow
            label="Deadline"
            value={`${daysToStaffingDeadline ?? 0}/${deadlineInDays ?? 0}`}
            caption="days"
          />
          <InfoRow
            label="Invited"
            value={`${(sourcingCount ?? 0).toString()}/30`}
            caption="candidates"
          />
          <InfoRow
            label="Application"
            value={`${(appliedCount ?? 0).toString()}/5`}
            caption="candidates"
          />
          <InfoRow
            label="Matching"
            value={`${(matchingCount ?? 0).toString()}/3`}
            caption="candidates"
          />
          <InfoRow
            label="Proposed"
            value={`${(proposedCount ?? 0).toString()}/2`}
            caption="candidates"
          />
        </div>
      </div>
    )
  }
)
JobHealthInfo.displayName = 'JobHealthInfo'

type InfoRowProps = { label: string; value: string; caption?: string }
const InfoRow = ({ label, value, caption }: InfoRowProps) => {
  return (
    <div className="grid grid-cols-2 justify-between pt-1 pb-2">
      <p className="">{label}</p>
      <p className="text-right relative">
        {value}
        {caption && (
          <span className="text-xs absolute right-0 top-full -mt-1 text-gray font-light">
            {caption}
          </span>
        )}
      </p>
    </div>
  )
}
