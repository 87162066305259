import { fullPercentageForValue } from '@upper/utils'
import chroma from 'chroma-js'
import { JOB_HEALTH_COLORS } from '../const/job'

export const jobHealthColorForValue = (
  value: number
): { background: string; color: string } => {
  const roundedHundredPercentage = fullPercentageForValue(value)
  let background = JOB_HEALTH_COLORS.red
  if (roundedHundredPercentage >= 40 && roundedHundredPercentage < 70) {
    background = JOB_HEALTH_COLORS.yellow
  } else if (roundedHundredPercentage >= 70) {
    background = JOB_HEALTH_COLORS.green
  }

  const color = chroma(background).darken(1.8).hex()
  return { background, color }
}
